h1,
.h1 {
  margin-top: 7px;
  //color: $gray-600;
}

// Blockquote styling for Markdown

blockquote,
.blockquote {
  @extend .blockquote;
  font-size: 0.95rem;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before,
.blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote:after,
.blockquote:after {
  color: #ccc;
  content: no-close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.navbar-brand:link {
  font-size: 2.5em;
  padding-bottom: 0;
}

.navbar {
  opacity: 0.85;
}

.navbar-underlay {
  background-image: url("../static/images/blueprint-bg-210.png");
  opacity: 0.85;
}

.sublogo {
  font-size: 30px;
  line-height: 34.5px;
}

.sublogo--top {
  font-size: 40px;
  margin: 0 0 -8px 10px;
}

.sublogo-text--top {
  color: #727272;
  vertical-align: middle;
}

.sublogo--middle {
  font-size: 40px;
}

.sublogo-text--middle {
  color: #727272;
  vertical-align: top;
  display: inline-block;
}

.sublogo--bottom {
  font-size: 40px;
  margin: -50px 0 0 10px;
}

.sublogo-text--bottom {
  color: #727272;
  vertical-align: top;
  display: inline-block;
  margin-top: -5px;
}

.spin-reverse {
  -webkit-animation: spin-reverse 2s infinite linear;
  animation: spin-reverse 2s infinite linear;
}
@-webkit-keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.partner--image {
  height: 130px;
  vertical-align: middle;
}
